import React from 'react';
import MapArea from "./component/MapArea";


function App() {
    return (
        <div id="app">
            <MapArea />
        </div>
    );
}

export default App;
