import React from "react";
import {WelfareShop} from "../utils/ShopDataManager";
import {Popup} from "react-map-gl";
import {CoordConverter} from "../coord-converter";

export type PopupInfo = {
    latitude: number;
    longitude: number;
    welfareShop: WelfareShop;
}

export interface MapPopupProps {
    popupInfo: PopupInfo;
    onClose: () => void;
}

const MapPopup: React.FunctionComponent<MapPopupProps> = ({popupInfo, onClose}): React.ReactElement => {

    const meters = CoordConverter.degreesToMeters({
        latitude: popupInfo.welfareShop.위도,
        longitude: popupInfo.welfareShop.경도
    });

    const encodedName = encodeURIComponent(popupInfo.welfareShop.가맹점명);
    const encodedAddress = encodeURIComponent((popupInfo.welfareShop.소재지도로명주소 !== "") ?
        popupInfo.welfareShop.소재지도로명주소 : popupInfo.welfareShop.소재지지번주소 );

    const isMobileOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <Popup longitude={popupInfo.longitude} latitude={popupInfo.latitude} onClose={onClose}>
            <div className="map-popup">
                <h3 className="map-title">
                    {popupInfo.welfareShop.가맹점명}
                </h3>
                <div className="map-content">
                    <p className="road-base-address">{popupInfo.welfareShop.소재지도로명주소}</p>
                    <p className="lot-number-address">(지번){popupInfo.welfareShop.소재지지번주소}</p>
                    <a className="phone-number" href={`tel://${popupInfo.welfareShop.전화번호}`}>{popupInfo.welfareShop.전화번호}</a>
                    {isMobileOS ?
                        // 모바일일 경우 앱으로 연결
                        (<a className="map-link naver" href={`nmap://place?lng=${popupInfo.welfareShop.경도}&lat=${popupInfo.welfareShop.위도}&name=${encodedName}&appname=dev.rajephon.dreamtree`} target="_blank">네이버 지도</a>)
                        :
                        // 모바일이 아닐 경우 브라우저로 연결
                        (<a className="map-link naver" href={`https://map.naver.com/v5/?c=${meters.x},${meters.y},15,0,0,0,dh&lng=${popupInfo.welfareShop.경도}&lat=${popupInfo.welfareShop.위도}&type=0&title=${encodedName}`} target="_blank">네이버 지도</a>)}
                    <a className="map-link kakao" href={`https://map.kakao.com/link/map/${encodedName},${popupInfo.welfareShop.위도},${popupInfo.welfareShop.경도}`} target="_blank">카카오 맵</a>
                </div>
            </div>
        </Popup>
    );
};

export default MapPopup;
