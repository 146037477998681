export class CoordConverter {

    static degreesToMeters = ({latitude, longitude}:{latitude: number, longitude: number}): { x: number; y: number } => {
        const x = (longitude * 20037508.34) / 180;
        let y =
            Math.log(Math.tan(((90 + latitude) * Math.PI) / 360)) /
            (Math.PI / 180);
        y = (y * 20037508.34) / 180;
        return {x, y};
    };

    static metersToDegrees = (x: number, y: number):{lat:number, lon:number} => {
        const lon = (x * 180) / 20037508.34;
        const lat =
            (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI -
            90;
        return {lat, lon};
    };
}
