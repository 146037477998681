import * as Papa from "papaparse";

export type WelfareShop = {
    가맹점명: string,
    가맹점유형코드: number,
    시도명: string,
    시군구명: string,
    시군구코드: number,
    소재지도로명주소: string,
    소재지지번주소: string,
    위도: number,
    경도: number,
    전화번호: string,
    평일운영시작시각: string,
    평일운영종료시각: string,
    토요일운영시작시각: string,
    토요일운영종료시각: string,
    공휴일운영시작시각: string,
    공휴일운영종료시각: string,
    배달시작시각: string,
    배달종료시각: string,
    관리기관명: string,
    관리기관전화번호: string,
    데이터기준일자: Date,
    제공기관코드: number,
    제공기관명: string
};

export class ShopDataManager {
    private static instance: ShopDataManager;

    private constructor() {
    }

    public static getInstance(): ShopDataManager {
        if (ShopDataManager.instance == null) {
            ShopDataManager.instance = new ShopDataManager();
        }
        return ShopDataManager.instance;
    }

    public parse(url: string, onComplete:(newRecordList:WelfareShop[])=>void, onError:()=>void) {
        // withCredentials: true maybe?
        const config: Papa.ParseRemoteConfig<WelfareShop> = {
            download: true,
            header: true,
            // skipEmptyLines: true,
            // dynamicTyping: true,
            // worker: true,
            // comments: "#",
            // delimiter: ",",
            // newline: "\r\n",
            complete: (results: Papa.ParseResult<WelfareShop>) => {
                console.log("parse complete");
                // console.log(results.data)

                // TODO: 위도 경도 정보 없는 레코드 스킵 처리 (with warning)
                const newRecordList:WelfareShop[] = [];
                if (results.data.length > 0) {
                    console.log(results.data[0].가맹점명);
                    for (let i = 0; i < results.data.length; i++) {
                        // if (i >= 50)
                        //     break;
                        const record = results.data[i];
                        const newRecord = {
                            가맹점명: record.가맹점명,
                            가맹점유형코드: parseInt(record.가맹점유형코드 + ''),
                            시도명: record.시도명,
                            시군구명: record.시군구명,
                            시군구코드: parseInt(record.시군구코드 + ''),
                            소재지도로명주소: record.소재지도로명주소,
                            소재지지번주소: record.소재지지번주소,
                            위도: parseFloat(record.위도 + ''),
                            경도: parseFloat(record.경도 + ''),
                            전화번호: record.전화번호,
                            평일운영시작시각: record.평일운영시작시각,
                            평일운영종료시각: record.평일운영종료시각,
                            토요일운영시작시각: record.토요일운영시작시각,
                            토요일운영종료시각: record.토요일운영종료시각,
                            공휴일운영시작시각: record.공휴일운영시작시각,
                            공휴일운영종료시각: record.공휴일운영종료시각,
                            배달시작시각: record.배달시작시각,
                            배달종료시각: record.배달종료시각,
                            관리기관명: record.관리기관명,
                            관리기관전화번호: record.관리기관전화번호,
                            데이터기준일자: new Date(record.데이터기준일자),
                            제공기관코드: parseInt(record.제공기관코드 + ''),
                            제공기관명: record.제공기관명
                        };
                        if (isNaN(newRecord.위도) || isNaN(newRecord.경도)) {
                            continue;
                        }
                        newRecordList.push(newRecord);
                    }
                }
                // console.log(this.data);
                onComplete(newRecordList);
            },
            error: (error: Error) => {
                console.log("parse error");
                console.error(error);
                onError();
            }
        };
        Papa.parse(url, config);
    }
}
