import React from "react";
import {ShopDataManager, WelfareShop} from "../utils/ShopDataManager";
import ReactMapGL, {Source, Layer, GeolocateControl, AttributionControl} from 'react-map-gl';
import {MapEvent} from "react-map-gl/src/components/interactive-map";
import {MapRef} from "react-map-gl/src/components/static-map";
import {Feature, Point} from "geojson";
import MapPopup, {PopupInfo} from "./MapPopup";

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type Viewport = {
    latitude: number;
    longitude: number;
    zoom: number;
    bearing: number;
    pitch: number;
    transitionDuration: number;
};

console.log(`token: ${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`);
console.log(`path: ${process.env.REACT_APP_DATA_PATH}`);

const MapArea = () => {
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
    const [shopList, setShopList] = React.useState<Array<Feature<Point, WelfareShop>>>([]);
    const [popupInfo, setPopupInfo] = React.useState<PopupInfo|null>(null);

    const mapRef = React.useRef<MapRef>(null);
    const [viewport, setViewport] = React.useState<Viewport>({
        latitude: 37.413294,
        longitude: 126.734086,
        zoom: 9,
        bearing: 0,
        pitch: 0,
        transitionDuration: 500
    });

    const sourceId = "welfare-shop-list";
    const clusterId = "clusters";
    React.useEffect(() => {
        console.log("ShopDataManager parse");
        setIsDataLoaded(false);
        const host = window.location.host;
        const protocol = window.location.protocol;
        ShopDataManager.getInstance().parse(`${protocol}//${host}/${process.env.REACT_APP_DATA_PATH}`,
            (welfareShopList) => {
                setShopList(welfareShopList.map((shop) => {
                    return {
                        type: "Feature",
                        properties: shop,
                        geometry: {
                            type: "Point",
                            coordinates: [shop.경도, shop.위도]
                        }
                    }
                }));
                // setShopList(newShopList);
                setIsDataLoaded(true);
            },
            () => {
                setIsDataLoaded(true);
            });
    }, []);

    // const {isLoaded:isMapLoaded} = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyBXULiKufHlckJ0TaiYy_9dKJQXFhlnRt4"
    // })

    const onClickCluster = (event:MapEvent) => {
        if (!event.features) {
            console.error("event.features is undefined");
            return;
        }
        if (!mapRef.current) {
            console.error("mapRef.current is undefined");
            return;
        }

        const feature = event.features[0];
        const clusterId = feature.properties.cluster_id;

        const mapboxSource = mapRef.current.getMap().getSource(sourceId);

        mapboxSource.getClusterExpansionZoom(clusterId, (err:any, zoom:number) => {
            if (err) {
                console.error(err);
                return;
            }
            console.log(`${JSON.stringify(viewport)}`);
            console.log(JSON.stringify(feature.geometry));
            const newViewport:Viewport = {...viewport};
            newViewport.longitude = Number(feature.geometry.coordinates[0]);
            newViewport.latitude = Number(feature.geometry.coordinates[1]);
            newViewport.zoom = zoom;
            newViewport.bearing = viewport.bearing;
            newViewport.pitch = viewport.pitch;
            newViewport.transitionDuration = viewport.pitch;
            setViewport(newViewport);
        });
    };

    const onClick = (event:MapEvent) => {
        if (!event.features) {
            console.error("event.features is undefined");
            return;
        }
        if (!mapRef.current) {
            console.error("mapRef.current is undefined");
            return;
        }
        console.log("event");
        console.log(event);
        if (event.features.length === 0) {
            console.error("event.features.length === 0");
            return;
        }
        const feature = event.features[0];
        console.log("feature", JSON.stringify(feature))
        // const currentClusterId = feature.properties.cluster;
        if (feature.properties.cluster) {
            onClickCluster(event);
        }else {
            const newPopupInfo:PopupInfo = {
                latitude: feature.geometry.coordinates[1],
                longitude: feature.geometry.coordinates[0],
                welfareShop: feature.properties as WelfareShop
            };
            setPopupInfo(newPopupInfo);
            return;
        }
    };


    if (!isDataLoaded)
        return (<>데이터 로딩중...</>);

    const attribution = "Developed by <a href='https://rajephon.dev/' target='_blank'>rajephon</a> | <a href='https://www.data.go.kr/data/15034530/standard.do' target='_blank'>데이터 출처 © 공공데이터포털</a>";

    return <>
        <ReactMapGL
            {...viewport}
            width={"100%"}
            height={"100%"}
            mapStyle="mapbox://styles/mapbox/dark-v9"
            onViewportChange={setViewport}
            interactiveLayerIds={[clusterId, "unclustered-point"]}
            onClick={onClick}
            ref={mapRef}
            attributionControl={false}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        >
            {popupInfo && (
                <MapPopup popupInfo={popupInfo} onClose={() => {setPopupInfo(null)}} />
            )}
            <Source id={sourceId} type={'geojson'} data={{type: "FeatureCollection", features: shopList}} cluster={true} clusterMaxZoom={12}>
                <Layer id={clusterId} source={sourceId} type={'circle'} filter={['has', 'point_count']} paint={{'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
                    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]}} />
                <Layer id='cluster-count' source={sourceId} type={'symbol'} filter={['has', 'point_count']}
                       layout={{'text-field': '{point_count_abbreviated}', 'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], 'text-size': 12}}
                       paint={{"text-color": "black"}}/>
                <Layer id='unclustered-point' source={sourceId}
                       type={'circle'} filter={['!', ['has', 'point_count']]}
                       paint={{'circle-color': '#11b4da', 'circle-radius': 5, 'circle-stroke-width': 1, 'circle-stroke-color': '#fff'}} />
                <Layer id='unclustered-symbol' source={sourceId} type={'symbol'} filter={['!', ['has', 'point_count']]}
                       layout={{'text-field': '{가맹점명}', 'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], 'text-size': 12, "text-offset": [0,-1.1]}}
                       paint={{"text-color": "white"}}/>
            </Source>
            <GeolocateControl
                style={{left: '10px', bottom: '40px'}}
                positionOptions={{enableHighAccuracy: true}}
                showUserLocation={true}
                trackUserLocation={false}
                fitBoundsOptions={{maxZoom: 15}}
            />
            <AttributionControl compact={false} style={{right:0, bottom: 0}} customAttribution={attribution} />

        </ReactMapGL>
    </>
}

export default MapArea;
